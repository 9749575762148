<template>
	<div>
		<div
			class="pageHomeViewByCourseCarousel"
			:id="`accordion-${category.id}`"
			v-for="category in getAllCategories"
			:key="category.id"
		>
			<CarouselSection :loading="loading" :courses="courses" :category="category" @reachEnd="loadMore()" :viewType="viewType" />
		</div>

		<infinite-loading
			@infinite="infiniteHandler"
			v-if="courses.length > 0"
		>
			<div slot="no-results"></div>
			<div slot="no-more"></div>
			<div slot="no-results"></div>
			<div slot="error"></div>
		</infinite-loading>
	</div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapGetters, mapActions } from "vuex";
import CarouselSection from "@/components/pages/home/viewByCourse/CarouselSection.vue";

export default {
	beforeDestroy() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	computed: {
		...mapGetters({
			getCoursesList: "home/getCoursesList",
			getAllCategories: "home/getAllCategories",
			getHideDisabledCourses: "config/getHideDisabledCourses",
		}),
		courses: {
			get() {
				if (this.getHideDisabledCourses) {
					return this.getCoursesList.filter(item => item?.has_access);
				} else {
					return this.getCoursesList;
				}
			},
		},
	},
	components: {
		InfiniteLoading,
		CarouselSection,
	},
	props: {
		loading: {
			type: Boolean,
		},
		viewType: {
			type: String,
		}
	},
	methods: {
		...mapActions({
			actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
			actionFetchHomeData: "home/actionFetchHomeData",
		}),
		async loadData(params) {
			await this.actionFetchHomeData(params);
			const courses = [...this.courses];
			const dataCourses = this.organizedData(courses);
			this.general = [dataCourses];
		},
		async infiniteHandler($state) {
			if (this.page === this.lastPage) {
				$state.complete();
				return;
			}
			this.page += 1;
			try {
				await this.loadData({ page: this.page });
				$state.loaded();
			} catch (error) {
				$state.error();
			}
		},
		async loadMore() {
			this.page += 1;
			await this.actionFetchHomeData({ page: this.page });
		},
	},
	data() {
		return {
			page: 1,
		};
	},
};
</script>

<style lang="scss" scoped>
.pageHomeViewByCourseCarousel {
	position: relative;
	margin-bottom: 64px;
}
</style>
